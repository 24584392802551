.labor-board-associate-view-table-column {
  border-inline-start: 1px solid #b1bac3;
  box-shadow: 2px 0px #b2bbc2;
}

.labor-board-employee-icon {
  border-radius: 50%;
  padding: 2px;
}

.labor-board-employee-icon-empty-div {
  border-radius: 50%;
  padding: 2px;
  border: 2px Dashed #0085b3 !important;
}

.labor-board-employee-icon-empty {
  color: #0085b3;
  width: 50px !important;
  height: 50px !important;
}

.employee-error-popup {
  color: #DC143C !important;
}

.employee-error-popup-button {
  background-color: #DC143C !important;
  color: white !important;
  border-radius: 4px;
  border-color: #DC143C !important;
}
