:root,
html,
body,
#root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.screen2-plan-menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  border-right: 1px solid #bec3c4 !important;
}

#upsert-aisles-menu button[disabled] {
  cursor: default;
}


.screen2-content {
  width: 70vw;
  margin-right: 0 !important;
}

.alert-border {
  border: 1px solid #cfd1d3;
}

.menu-text-color {
  color: #3b8caa !important;
}

.horizontal-scroll {
  overflow-x: scroll !important;
}

.associate-row div {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.green-bold-text-color {
  color: green !important;
  font-weight: bold;
}

.red-bold-text-color {
  color: red !important;
  font-weight: bold;
}

.badge-spacing {
  position: absolute;
  top: 8px;
  right: 0px;
}

.leftCenter {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
}

.rightCenter {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.centerCenter {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.spaceCenter {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rate-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  align-items: center; /* Centers the content horizontally */
}
.msr-rate,
.expected-range {
  margin: 0; /* Removes default margin */
}

.centered-header {
  flex-direction: column;
  align-items: center; /* Centers the content horizontally */
  text-align: center; /* Centers the text within the div */
}

.centered-text {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the content horizontally */
  text-align: center; /* Centers the text within the div */
}
.centered-text > div {
  margin: 5px 0; /* Adds vertical spacing between the elements */
}

.header-with-tooltip {
  display: flex;
  align-items: center; /* Vertically center the text and icon */
}
.custom-tool-tip {
  margin-left: 8px; /* Adds some space between the text and the tooltip icon */
}

.icon-white { color: white }

.clusterDetailCardTitle {
  color: white !important;
}

.loaderClass {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.labor-board-associate-view-table-column {
  border-inline-start: 1px solid #b1bac3;
  box-shadow: 2px 0px #b2bbc2;
}

.labor-board-employee-icon {
  border-radius: 50%;
  padding: 2px;
}

.labor-board-employee-icon-empty-div {
  border-radius: 50%;
  padding: 2px;
  border: 2px Dashed #0085b3 !important;
}

.labor-board-employee-icon-empty {
  color: #0085b3;
  width: 50px !important;
  height: 50px !important;
}

.employee-error-popup {
  color: #DC143C !important;
}

.employee-error-popup-button {
  background-color: #DC143C !important;
  color: white !important;
  border-radius: 4px;
  border-color: #DC143C !important;
}

.styles_verticalText__1cHcm {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  /*background-color: red;*/
  /*border: 1px solid;*/
}

.styles_noMarginRow1__2WuiO {
  margin: 0 !important;
}

div.styles_noMarginRow2__2rtnQ {
  margin: 0 !important;
  /* width: 100% !important; */
}

.styles_noMarginRow3__1HoGl {
  margin: 0 !important;
}

.styles_processDetailCardTitle__3xvst {
  color: white;
}

