.loaderClass {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
