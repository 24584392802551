:root,
html,
body,
#root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.screen2-plan-menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  border-right: 1px solid #bec3c4 !important;
}

#upsert-aisles-menu button[disabled] {
  cursor: default;
}


.screen2-content {
  width: 70vw;
  margin-right: 0 !important;
}

.alert-border {
  border: 1px solid #cfd1d3;
}

.menu-text-color {
  color: #3b8caa !important;
}

.horizontal-scroll {
  overflow-x: scroll !important;
}

.associate-row div {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.green-bold-text-color {
  color: green !important;
  font-weight: bold;
}

.red-bold-text-color {
  color: red !important;
  font-weight: bold;
}

.badge-spacing {
  position: absolute;
  top: 8px;
  right: 0px;
}

.leftCenter {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.rightCenter {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.centerCenter {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.spaceCenter {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rate-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  align-items: center; /* Centers the content horizontally */
}
.msr-rate,
.expected-range {
  margin: 0; /* Removes default margin */
}

.centered-header {
  flex-direction: column;
  align-items: center; /* Centers the content horizontally */
  text-align: center; /* Centers the text within the div */
}

.centered-text {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the content horizontally */
  text-align: center; /* Centers the text within the div */
}
.centered-text > div {
  margin: 5px 0; /* Adds vertical spacing between the elements */
}

.header-with-tooltip {
  display: flex;
  align-items: center; /* Vertically center the text and icon */
}
.custom-tool-tip {
  margin-left: 8px; /* Adds some space between the text and the tooltip icon */
}

.icon-white { color: white }

.clusterDetailCardTitle {
  color: white !important;
}
